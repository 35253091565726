import React from "react";
import BaseLayout from "../../components/layouts/baseLayout";
import TextJumbotron from "../../components/util/textJumbotron";
import { Container, Row, Col } from "react-bootstrap";
import LittleGirlSmilingImage from "../../components/images/littleGirlSmiling";
export default function OaksPage({ data }) {
  return (
    <BaseLayout pageTitle="Oaks Preschool Class">
      <TextJumbotron
        heading="THE OAKS PROGRAM"
        body="(5-YEAR-OLD, TAUGHT SEPT-JUNE)"
      />
      <Container>
        <Row>
          <Col>
            <p className="lead font-weight-bold text-center">
              The Oaks program is is for children who are 5 years old by
              December 31.
            </p>
            <p>
              The Oaks program is a year of incredible social growth for
              children. We are highly aware of this and build each day around
              positive social development. We encourage the children to empower
              themselves with the skills they need in order to grow into caring,
              understanding individuals.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="mx-auto pt-3 pb-3 my-auto">
            <LittleGirlSmilingImage className="rounded h-50" />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h2 className="text-center pb-3 pt-3">Class Highlights </h2>
          </Col>
          <Col xs={12} lg={4}>
            <h3 className="text-center pb-2">Math & Science</h3>
            <ul>
              <li>Counting 1 – 100</li>
              <li>Number recognition & placement</li>
              <li>before/after</li>
              <li>Counting by 2s</li>
              <li>Left and right placement</li>
              <li>Telling the time</li>
              <li>Addition, subtraction</li>
              <li>Use of a ruler (measurements)</li>
              <li>Graphing</li>
              <li>Weather</li>
              <li>Colour</li>
              <li>Theme-related aspects, e.g. space, polar animals</li>
              <li>The human body</li>
              <li>Rainbows</li>
            </ul>
          </Col>
          <Col xs={12} lg={4}>
            <h3 className="text-center pb-2">Phonics / Literature</h3>
            <ul>
              <li>20 double letter sounds</li>
              <li>5 short vowel sounds</li>
              <li>15 long vowel sounds</li>
              <li>40 tricky/sight words</li>
              <li>Ongoing review of new sounds</li>
              <li>Daily printing and letter formation</li>
              <li>Dictation</li>
              <li>Reading with a teacher</li>
              <li>Independent reading</li>
              <li>Group reading</li>
              <li>Theme-related story every day</li>
              <li>Use of word games</li>
              <li>
                Social stories to enable children to understand their changing
                social development
              </li>
            </ul>
          </Col>
          <Col xs={12} lg={4}>
            <h3 className="text-center pb-2">Art, Music and Play</h3>
            <ul>
              <li>Daily circle time</li>
              <li>Theme-related songs and movement</li>
              <li>Yoga</li>
              <li>Phonics and grammar are incorporated in art activities</li>
              <li>Artistic growth encouraged by means of various mediums</li>
              <li>Creativity through artistic expression</li>
              <li>
                Encourage language development and fine motor control through
                art
              </li>
              <li>Painting, cutting, gluing, coloring</li>
              <li>Encourage social growth through play</li>
              <li>
                Activity centres (dramatic play area, floor toys, block play,
                etc.)
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </BaseLayout>
  );
}
